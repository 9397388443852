import React, { useState } from 'react';

import './SeasonPassPersonalInfo.sass';
import styles from './SeasonPassPersonalInfo.module.sass';
import Icon from '../../../components/UI/Icon/Icon';

import Swal from "sweetalert2";
import queryString from "query-string";
import withReactContent from "sweetalert2-react-content";
import { EditMembershipCardInfoModal } from './Modals/EditMembershipCardInfo';
import { getCurrentLanguageTexts } from '../../../constants/seasonPassPersonalInfoLangs';
import { BuySeasonPassModal } from './Modals/BuySeasonPassModal';
import { StatusLabel } from '../../../components/Transactions/StatusLabel/StatusLabel';

import axios from "../../../axios-instance";
import NewLoader from '../../../components/NewLoader/NewLoader';
import { Tooltip } from '../../../components/UI/Tooltip/Tooltip';
import { toast } from 'react-toastify';

const MySwal = withReactContent(Swal);

export const MembershipCard = ({ onSave, membershipCard, currencyLabel, onConfirmBuySeasonPass, availablePoints, eventInfo, onConfirmBuyNfc, ableToBuyNFC }) => {
    const txt = getCurrentLanguageTexts();;

    const [allowResendTicket, setAllowResendTicket] = useState(!!membershipCard.transactionId);

    const hasSeat = !!membershipCard.seat;
    const isLoading = ('loading' in membershipCard);
    const allowEdit = !membershipCard.hasSeasonPass && !isLoading;
    const showEditMembershipCardInfo = () => {
        if (!allowEdit) {
            return;
        }

        MySwal.fire({
            title: txt.EditMembershipCardInfoModalTxt['title'],
            html: (
                <EditMembershipCardInfoModal
                    membershipCard={membershipCard}
                    editMembeshipCardInfo={onSave}
                    eventInfo={eventInfo} />
            ),
            showConfirmButton: false,
            customClass: "public-user-modal",
        });
    };

    const showBuySeasonPassModal = () => {
        if (membershipCard.hasSeasonPass) {
            return;
        }

        const requests = [];

        const getSeatLabel = (ticket) => {
            return ticket.area + (!!ticket.side ? `-${ticket.side}` : '') + `-${ticket.row}-${ticket.seat}`
        }

        requests.push(axios.get(`TicketTemplate/${process.env.REACT_APP_NEW_SEASON_EVENT_ID}/${getSeatLabel(membershipCard)}?hasSide=${!!membershipCard.side}`),
            axios.get(`EventPublicInfo/BySlug/${process.env.REACT_APP_NEW_SEASON_EVENT_SLUG}`));

        Promise.all(requests)
            .then((responses) => {
                MySwal.fire({
                    title: txt.BuySeasonPassModalTxt['title'],
                    html: (
                        <BuySeasonPassModal
                            membershipCard={membershipCard}
                            ticketTemplate={responses[0].data}
                            availablePoints={availablePoints}
                            onConfirmBuySeasonPass={onConfirmBuySeasonPass}
                            event={responses[1].data}
                        />
                    ),
                    showConfirmButton: false,
                    customClass: "public-user-modal",
                });
            })
    }

    const resendTicket = () => {
        const token = queryString.parse(window.location.href.split('?')[1]).token

        if (token) {
            axios.post(`SeasonPassMember/ResendTicket?transactionId=${membershipCard.transactionId}&accessToken=${token}`)

            setAllowResendTicket(false);

            setTimeout(() => { setAllowResendTicket(true) }, 60000)
        }
        else {
            toast.error('Ticket not sended')
        }
    }

    const buyNfc = () => {
        onConfirmBuyNfc(membershipCard);
    }


    return <>
        <li className={styles['membership-card']}>

            <div className={styles['membership-card-edit'] + (allowEdit ? (' ' + styles['active']) : '')}
                onClick={showEditMembershipCardInfo}>

                <div className={styles['ticket-and-price']}>

                    <p className="partizan-open-selling__text-normal-small">{membershipCard.ticketName}</p>

                    <p className={styles['price-label'] + " partizan-open-selling__text-bold"}>{membershipCard.ticketPrice} {currencyLabel}</p>

                </div>

                {membershipCard.customerName &&
                    <p>{txt.SeasonPassListTxt['for']} {membershipCard.customerName}</p>
                }

                <div className={styles['footer']}>

                    {hasSeat &&
                        <p className={styles['seat-label']}><strong>{membershipCard.area} {
                            membershipCard.side === 'L' && 'Left'
                            || membershipCard.side === 'R' && 'Right'
                            || membershipCard.side === 'M' && 'Center'
                            || ''
                        } {membershipCard.row} {membershipCard.seat}</strong></p>}

                    {membershipCard.hasNFCCard &&
                        <StatusLabel className={styles['edit-icon']}
                            text='+ NFC Card'
                            statusValue='NFCBought' />}
                    {ableToBuyNFC && !!!membershipCard.hasNFCCard && membershipCard.hasSeasonPass &&
                        <button
                            className={'btn-primary'}
                            onClick={buyNfc}>
                                <StatusLabel className={styles['edit-icon']}
                                    text='+ NFC'
                                    statusValue='NFCBuy' />
                        </button>}

                    {allowEdit &&
                        <Icon className={styles['edit-icon']} name='edit' />}

                </div>

                {isLoading &&
                    <NewLoader className='' />
                }

            </div>

            {membershipCard.hasSeasonPass
                ? <div className={styles['season-pass-buyed']}>

                    <p className={styles['season-pass-buyed-label']}>
                        {txt.SeasonPassListTxt['seasonPassPurchased']}
                    </p>


                    <button disabled={!allowResendTicket}
                        className={'btn-primary ' + styles['season-pass-buyed-icon-button']}
                        onClick={resendTicket}>
                        <Tooltip description={allowResendTicket ? txt.SeasonPassListTxt['toolTipResend'] : txt.SeasonPassListTxt['tooltipPleaseWait']}
                            className={styles['tooltip']}>

                            <Icon className={styles['season-pass-buyed-icon-button-icon']}
                                color={allowResendTicket ? '#fff' : '#fff'}
                                name='send-email' />

                        </Tooltip>

                    </button>


                </div>
                : <button disabled={(true && hasSeat && membershipCard.customerName) ? undefined : true} className='btn-primary' onClick={showBuySeasonPassModal}>{txt.SeasonPassListTxt['buySeasonPass']}</button>
            }
        </li>
    </>
}

export const MembershipCardContainer = ({ onSave, membershipCards, currencyLabel, onConfirmBuySeasonPass, availablePoints, eventInfo, onConfirmBuyNfc, ableToBuyNFC  }) => {
    return <>
        <ul className={styles['membership-cards-container']}>

            {membershipCards.map(m =>
                <MembershipCard
                    key={m.id}
                    onSave={onSave}
                    membershipCard={m}
                    currencyLabel={currencyLabel}
                    onConfirmBuySeasonPass={onConfirmBuySeasonPass}
                    availablePoints={availablePoints}
                    eventInfo={eventInfo}
                    onConfirmBuyNfc={onConfirmBuyNfc}
                    ableToBuyNFC={ableToBuyNFC} />)}

        </ul>
    </>
}